<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
        v-if="!api.isLoading || data!=null"
        :items="data"
        :headers ="dataHeader"
        :search = "search"
        class="elevation-1 mt-5">
            <template
                v-slot:item.Index="{item}">
                <span>
                    {{ data.indexOf(item)+1 }}
                </span>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    width="auto"
                    height="auto"
                    class="py-3"
                    flat>
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-col>

                                    <v-toolbar-title
                                        class="font-weight-bold text-h4 my-3">
                                        Demo Request
                                    </v-toolbar-title>
                                </v-col>
                                <v-col> 
                                    <div class="d-flex justify-end">

                                        <!-- <v-btn 
                                            color="primary"
                                            :to="{name:'PageDemonstrationCreate'}">
                                            New
                                        </v-btn> -->
                                    </div>
                                </v-col>
                                <!-- <div class="d-flex justify-between">
                                </div> -->
                            </v-row>
                            <v-divider>
                            </v-divider>
                            <v-row class="mt-3">
                                <v-text-field
                                    dense
                                    outlined 
                                    clearable 
                                    v-model="search"
                                    label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template
                v-slot:item.referral_uuid="{item}">
                <span
                    v-if="item.referral_uuid!=null">
                    {{ item.referral_uuid }}<br/><b>

                        {{ item.user_email }} 
                    </b>
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.name="{item}">
                <span>
                    {{ item.fname+" "+item.lname }}
                </span>
            </template>
            <template
                v-slot:item.status="{item}">
                <router-link
                    :to="{name:'PageDemonstrationUpdate',params:{id:item.book_demo_id}}">
                    {{ item.status }}
                </router-link>
            </template>
            <template v-slot:item.demo_at="{item}">
                <span
                    v-if="item.demo_at!=null"> 
                    {{ convertTimeZone(item.demo_at) }}
                </span>
                <span
                    v-else> 
                    -
                </span>
            </template>
            <template  
                v-slot:item.pic_email="{item}">
                <router-link
                    v-if="item.pic_id!=null"
                    :to="{name:'PageStaffDetail',params:{id:item.pic_id}}">
                    {{ item.pic_email }}
                </router-link>
                <span
                    v-else> 
                    -
                </span>
            </template>
            <template
                v-slot:item.created_at="{item}">
                <span>
                    {{ convertTimeZone(item.created_at) }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        search:"",
        statusOptions:['Pending','Booked','Completed'],
        data:null,
        dataHeader:[
            {
                text:"#",
                value:'Index',
                align:'start'
            },
            {
                text:'Name',
                value:'name',
            },
            {
                text:'Email',
                value:'email',
            },
            {
                text:'Mobile',
                value:'mobile'
            },
            {
                text:'Company',
                value:'company',
            },
            {
                text:"Referral id",
                value:"referral_uuid",
            },
            {
                text:'Date',
                value:'created_at'
            },
            {
                text:'Request demo date',
                value:'demo_at'
            },
            {
                text:'PIC',
                value:'pic_email',
            },
            {
                text:'Status',
                value:'status'
            }
        ],
        allowedRolesViewAll:['developer','vision_admin','operation'],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getAllDemonstration") {
                this.data = resp.data;
            }
            if(resp.class==="getAssignedDemoRequest") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            
            if(this.allowedRolesViewAll.some(role=>this.$store.getters.getRole.includes(role))) {
                let fetchDemonstrationListApi = this.fetchDemonstrationList();
                this.$api.fetch(fetchDemonstrationListApi);
    
            }
            else{
                let fetchDemonstrationListApi = this.fetchDemonstrationPartialList();
                this.$api.fetch(fetchDemonstrationListApi);

            }
        },
        fetchDemonstrationPartialList(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/demonstration/assign/"+this.$store.getters.getUserId;
            return tempApi;
        }, 
        fetchDemonstrationList() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/demonstration";
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
    }
}
</script>